import { motion } from 'framer-motion'
import { Col } from 'react-bootstrap'
import background from './../images/Awards_Page_Image_002-min.jpg'
import awards from "./info/awardsText"
import credits from "./info/credits"
import NavBar from './NavBar'
import Footer from './Footer'


const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2, duration: 0.75 }
  },
  exit: {
    y: "-100vw",
    transition: { ease: 'easeInOut' }
  }
}

function About() {

  return (
    <motion.div className="App-body about"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="container-fluid d-flex flex-column" style={{
        backgroundImage: "url(" + background + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh'
      }}>
        <NavBar/>
        <div className="row vertical-center px-2 mb-5">
          <Col md={6}>
            <div className="awards-list">
              <h5 className="heading">AWARDS</h5>
              {awards.map((award, index) => {
                return (
                  <div key={index} className="awards border-top">
                    <div>{award.title}</div>
                    <div>{award.award}</div>
                  </div>
                );
              })}
            </div>

            <div className="awards-list">
              <h5 className="heading">SELECTED COMPOSER CREDITS</h5>
              {credits.map((credit, index) => {
                  return (
                    <div key={index} className="credit border-top">
                      <div>{credit.title}</div><div className='credits'>{credit.info}</div>
                    </div>
                  );
                })}
            </div>
          </Col>
        </div>
        <Footer page={'awards'}/>
      </div>
    </motion.div>
  );
}

export default About;
