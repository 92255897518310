import {
  Routes,
  Route,
  useLocation
} from "react-router-dom"
import Home from './components/Home'
import Awards from './components/Awards'
import Contact from './components/Contact'

function App() {
  const location = useLocation()
  return (
    <div className="body-main">
      <Routes location={location} key={location.key}>
        <Route path="/" element={<Home />}/>
        <Route path="/awards" element={<Awards />}/>
        <Route path="/contact" element={<Contact />}/>
      </Routes>
    </div>
  );
}

export default App;


