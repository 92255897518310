
import { motion } from 'framer-motion'
import { Col } from 'react-bootstrap'
// import background from './../images/Homepage_Image_001-min.jpg'
import AudioPlayer from './AudioPlayer'
import tracks from "./info/tracks"
import NavBar from './NavBar'
import Footer from './Footer'

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2, duration: 0.75 }
  },
  exit: {
    y: "-100vw",
    transition: { ease: 'easeInOut' }
  }
}

function Home({ timeLeft }) {

  return (
    <motion.div className="App-body home"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="container-fluid d-flex flex-column" style={{
        minHeight: '100vh'
      }}>
        <NavBar/>
        <div className="video-background">
          <div className="video-foreground">
            <iframe title="Ben Locket video" src="https://player.vimeo.com/video/775007698?background=1" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
        <div className="row vertical-center px-2">
          <Col sm={10} lg={6} xl={4} className="text-start">
            <div className="two-columns text-start">
              <p>Ben Locket is a musician and composer.</p>
              <p>His score for the Oscar nominated ‘Revolting Rhymes’ was awarded Best Original Score at Sapporo Film Festival in Japan and has seen concert orchestra performances in Dublin, the National Concert Hall and London’s Royal Festival Hall.</p>
              <p>His work on BAFTA and Emmy winning series ‘The Amazing World of Gumball’ saw him nominated for ‘Best Music in a TV Production’ at LA’s Annie Awards and ‘Best Title Sequence’ at the UK Music and Sound Awards. Locket’s diverse musical style and&nbsp;</p>
              <p>storytelling focus led to his work with Dirty Three’s Jim White and Xylouris White for Curzon Cinemas. He composed an organic and cinematic reflection of the Curzon brand for their recent identity.</p>
              <p>From the minimalist arrangements of the London Design Museum’s Sir Paul Smith documentary to the synth led score of Zima Blue. The latter, produced for Netflix’s Emmy winning series ‘Love Death and Robots’, highlights the depth of his skill for scoring to picture.</p>
              <p>He is also one half of the instrumental group <a href="https://open.spotify.com/artist/0oulA4Aair1zei8l4ECS46?si=hCPSG59GSG6AhZYUNFhQLA&nd=1" rel="noreferrer" target="_blank">Tala Vala</a>.</p>
            </div>
          </Col>
        </div>

        <div className="row vertical-center py-5 mt-auto">
          <Col sm={9} lg={4} xl={3} className="text-start">
            <AudioPlayer tracks={tracks} />
          </Col>
        </div>
        <Footer page={'home'}/>
      </div>
    </motion.div>
  )
}

export default Home
