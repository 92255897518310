import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

function Footer({page}) {
  return (
    <footer className={`border-top d-flex flex-wrap justify-content-between align-items-right py-3 ${page === 'home' ? "mt-auto" : ""}`}>
      {page !== 'contact' &&
        <ul className="nav justify-content-end list-unstyled d-flex flex-column mb-3">
          <li>
            <a href="tel:+447791904175">+44 (0) 7791904175</a>
          </li>
          <li>
            <a href="mailto:benlocket@gmail.com">benlocket@gmail.com</a>
          </li>
        </ul>
      }
      {page !== 'contact' &&
        <ul className="nav justify-content-end list-unstyled d-flex">
          <li className="mx-2">
            <a href="https://twitter.com/benlocket" rel="noreferrer" target="_blank"><FontAwesomeIcon className="fa-2x" icon={faTwitter} /></a>
          </li>
        </ul>
      }
      <Container fluid className='g-0'>
        <Row className='g-0'>
          <Col xs={8} md={6} lg={4} className='sub-text'>
            <small>All rights of the produced and of the owner of the work reproduced reserved. Unauthorised copying, hiring, lending, public performance and broadcasting of recordings prohibited.</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
