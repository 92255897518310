const credits = [
  {
    title: 'Disney + Behind the Attraction',
    info: 'Documentary Title Sequence, 2021'
  },
  {
    title: 'Love, Death and Robots',
    info: 'TV Series, Netflix, 2019'
  },
  {
    title: 'String Quartetz Sync Score',
    info: 'Music for Tramworks Label, 2020/2021'
  },
  {
    title: 'Curzon Cinemas Identity',
    info: 'Nationwide brand score, 2018'
  },
  {
    title: 'The Amazing World of Gumball',
    info: 'TV Series Worldwide, 2010/2018'
  },
  {
    title: 'Woodchips',
    info: 'Short film, Nickelodeon, 2016'
  },
  {
    title: 'Someplace Awesome',
    info: 'Short film, Nickelodeon, 2016'
  },
  {
    title: 'Revolting Rhymes',
    info: 'Roald Dahl TV Series for BBC, 2016'
  },
  {
    title: '02: Help at Hand',
    info: '2 x 3 minute scores for advertising documnetary, 2014'
  },
  {
    title: 'A Day in the Life of a Fashion Show',
    info: 'Documentary, 2013'
  },
  {
    title: 'The Brightest Star in the Sky',
    info: 'Penguin Books Campaign UK, 2011'
  },
  {
    title: 'Dawn French: A Tiny Bit Marvellous',
    info: 'Penguin Books Campaign UK, 2011'
  },
  {
    title: 'AXA – The Shotton Family Disaster',
    info: '3 minute advertising drama documentary, 2010'
  },
  {
    title: 'Canon',
    info: 'Various advertising campaigns, 2006/2015'
  },
  {
    title: 'Muscle Worship',
    info: 'UK Channel 5 Documentary, 2007'
  },
  {
    title: 'In Your Dreams',
    info: 'UK Channel 5 Documentary, 2006'
  },
  {
    title: 'KIA Sportage',
    info: 'Worldwide TV Campaign, 2005'
  }
];

export default credits
