
import Blackwaters from "./../../sounds/1Blackwaters.mp3";
import DecemberSong from "./../../sounds/2DecemberSong.mp3";
import MemoryDevice from "./../../sounds/3MemoryDevice.mp3";
import TheKidnapping from "./../../sounds/4TheKidnapping.mp3";
import Metal from "./../../sounds/5Metal.mp3";
import JacksClimb from "./../../sounds/6JacksClimb.mp3";
import TheSpinoffs from "./../../sounds/7TheSpinoffs.mp3";

const tracks = [
  {
    title: 'Black waters',
    audioSrc: Blackwaters,
    trackLength: 179
  },
  {
    title: 'December Song',
    audioSrc: DecemberSong,
    trackLength: 112
  },
  {
    title: 'Memory Device',
    audioSrc: MemoryDevice,
    trackLength: 180
  },
  {
    title: 'The Kidnapping',
    audioSrc: TheKidnapping,
    trackLength: 165
  },
  {
    title: 'Metal',
    audioSrc: Metal,
    trackLength: 125
  },
  {
    title: 'Jacks Climb',
    audioSrc: JacksClimb,
    trackLength: 76
  },
  {
    title: 'The Spinoffs',
    audioSrc: TheSpinoffs,
    trackLength: 68
  }
];

export default tracks

