import { motion } from 'framer-motion'
import { Col } from 'react-bootstrap'
import background from './../images/STUDIO.jpg'
import NavBar from './NavBar'
import Footer from './Footer'

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.2, duration: 0.75 }
  },
  exit: {
    y: "-100vw",
    transition: { ease: 'easeInOut' }
  }
}

function Contact() {

  return (
    <motion.div className="App-body contact"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="container-fluid d-flex flex-column" style={{
        backgroundImage: "url(" + background + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh'
      }}>
        <NavBar/>
        <div className="row vertical-center px-2">
          <Col sm={6} md={3} className="text-start">
            <div className="contact-text">
              <p className='h5'><a href="tel:+447791904175">+44 (0) 7791904175</a></p>
              <p className='h5'><a href="mailto:benlocket@gmail.com">benlocket@gmail.com</a></p>
            </div>
          </Col>
          <Col sm={6} md={3} className="text-start">
            <div className="social-text">
              <p className='h5'><a href="https://twitter.com/benlocket" rel="noreferrer" target="_blank">Twitter</a></p>
              <p className='h5'><a href="https://open.spotify.com/artist/58aLmP16bjgJ8uiTn7RRmK" rel="noreferrer" target="_blank">Spotify</a></p>
            </div>
          </Col>
        </div>
        <Footer page={'contact'}/>
      </div>
    </motion.div>
  );
}

export default Contact
