import { useState } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'

function NavBar() {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(!show);

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className='mb-5'>
      <Container fluid>
        <Navbar.Brand href="/">Ben Locket</Navbar.Brand>
        <button onClick={handleShow} className={`d-lg-none hamburger hamburger--elastic ${show ? "is-active" : ""}`} type="button">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </Container>
      <Offcanvas show={show} onHide={handleShow} responsive="lg">
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-0 text-uppercase" defaultActiveKey="/home" as="ul">
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/awards">Awards</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            </Nav.Item>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
}

export default NavBar;
