const awardsText = [
  {
    title: 'SAPPORO SHORT FILM FESTIVAL JAPAN',
    award: 'Best original score 2017 – Won',
  },
  {
    title: 'UK MUSIC AND SOUND AWARDS',
    award: 'Best Title Music 2014 – Nominated',
  },
  {
    title: 'ANNIE AWARDS',
    award: 'Music in a television series 2012 – Nominated',
  },
  {
    title: 'PROMAX AWARDS',
    award: 'Best Title Music 2014 – Nominated',
  },
  {
    title: 'BRITISH ANIMATION AWARDS',
    award: 'Best use of Sound and music 2016 – Shortlisted',
  }
];

export default awardsText
