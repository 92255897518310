import { useState, useEffect, useRef } from 'react'
import AudioControls from './AudioControls'

const AudioPlayer = ({ tracks }) => {
  // State
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // Destructure for conciseness
  const { title, audioSrc, trackLength } = tracks[trackIndex];

  // Refs
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);

  // Destructure for conciseness
  const { duration } = audioRef.current;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true)
    }
    startTimer();
  };

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };

  const toTrack = (index) => {
    setTrackIndex(index)
  }

  const pause = () => {
    setIsPlaying(false)
  }

  const toTime = (seconds) => {
    if (typeof seconds == 'number' && seconds > 0) {
      var date = new Date(null);
      date.setSeconds(seconds);
      return date.toISOString().substr(15, 4);
    }
  }


  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()
      startTimer()
    } else {
      audioRef.current.pause()
    }
  }, [isPlaying]);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, [trackIndex]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    }
  }, []);

	return (
    <div className="audio-player">
      <div className="tracks-list">
      {tracks.map((track, index) => {
          return (
            <div key={index} className={`track-row ${trackIndex === index ? "active" : ""}`}>
              { trackIndex === index && isPlaying
              ? <button onClick={pause} className="controls inline"><i className="bi bi-pause-fill"></i></button>
              : <button onClick={() => toTrack(index)} className="controls inline"><i className="bi bi-play-fill"></i></button>
              }
              <span className='index'>{index+1}</span> <span className='track'>{track.title}</span>
              <span className='duration'>{toTime(track.trackLength)}</span>
            </div>
          );
        })}
      </div>
			<div className="track-info text-center my-4">
        <div className='track-progress'>
         {trackProgress && trackProgress > 0 ? toTime(trackProgress) : '0:00'}
          <input
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration ? duration : `${duration}`}
            className="progress"
            onChange={(e) => onScrub(e.target.value)}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
          /> {trackLength ? toTime(trackLength) : '0:00'}
        </div>
		    <h6 className="title">{title}</h6>
				<AudioControls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
        />
			</div>
		</div>
  )
}

export default AudioPlayer
